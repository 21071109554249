import { Box, Drawer, Typography, Divider } from "@mui/material";
import { fetchPost, formatStat, getRandomNumber, getStringHash } from "../util/helpers";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandYoutube,
} from "@tabler/icons-react";
import { memo } from "react";
import { Button } from "../components";
const platformToIcon = {
  youtube: (
    <IconBrandYoutube
      size="20"
      className="icon-colored"
      color="var(--main-text-color)"
    />
  ),
  tiktok: (
    <IconBrandTiktok
      size="20"
      className="icon-colored"
      color="var(--main-text-color)"
    />
  ),
  facebook: (
    <IconBrandFacebook
      size="20"
      className="icon-colored"
      color="var(--main-text-color)"
    />
  ),
  instagram: (
    <IconBrandInstagram
      size="20"
      className="icon-colored"
      color="var(--main-text-color)"
    />
  ),
};
function ApplicantDrawer({ open, onClose, creator, setCreator, campaignId }) {
    if (!creator) return null;

    const handleConfirmPartnership = async () => {
      console.log("creator", creator);
      if (
        creator.response &&
        creator.response.status === "matched"
      ) {
        return;
      }
      await fetchPost(`/brand/campaigns/${campaignId}/matches`, {
        creatorUserDocId: creator.userDocId,
        responseId: creator.response.id,
      });
      setCreator((creator) => {
        creator.response.status = "matched";
        return creator;
      });
 
      onClose();
    };
  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <Box style={{ minWidth: 300, width: "30vw", padding: 10 }}>
        <Box style={{ paddingBottom: 10, paddingTop: 20 }}>
          <Typography variant="h5">Creator Insights</Typography>
        </Box>
        <Divider />
        <Box className="creator-insights-container" display="flex" flexDirection="column" gap={1} justifyContent={"flex-start"} alignItems={"flex-start"}>
          <Box display="flex" flexDirection="row" gap={2}>
            <img
              className="profile-image"
              src={
                creator?.images?.length > 0
                  ? creator?.images[0]
                  : `../gradient${getStringHash(creator?.name, 10)}.png`
              }
              alt={creator.name}
            />
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="h4">{creator?.name}</Typography>
              <Box display="flex" flexDirection="row" gap={2}>
                {creator?.stats?.channels?.map((channel) => (
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.open(channel.url, "_blank");
                    }}
                  >
                    {platformToIcon[channel.platform]}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box style={{paddingBottom: 10, paddingTop: 10}}>
                <Typography variant="body1">
                    {creator?.description}
                </Typography>
        </Box>
        </Box>
       
        <Box style={{ paddingBottom: 10, paddingTop: 10 }}>
          {/* <Typography variant="h5">Overview</Typography> */}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          sx={{
            border: "1px solid var(--neutral-200)",
            borderRadius: 2,
            padding: 2,
          }}
        >
          <Box style={{ width: "33%", textAlign: "center" }}>
            <Typography variant="subtitle1">Followers</Typography>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              {formatStat(creator?.stats?.totalFollowers)}
            </Typography>
          </Box>
          <Box style={{ width: "33%", textAlign: "center" }}>
            <Typography variant="subtitle1">Reach</Typography>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              {formatStat(creator?.stats?.totalReach)}
            </Typography>
          </Box>
          <Box style={{ width: "33%", textAlign: "center" }}>
            <Typography variant="subtitle1">Posts</Typography>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              {formatStat(creator?.stats?.totalPosts)}
            </Typography>
          </Box>
        </Box>
        <Box style={{ paddingBottom: 10, paddingTop: 10 }}>
        <Typography variant="h5"  style={{textAlign: "center"}}>
          Content Preview
        </Typography>
        {/* <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          className="creator-insights-container"
          sx={{
            border: "1px solid var(--neutral-200)",
            borderRadius: 2,
            padding: 2,
          }}
        >
          <Box style={{ width: "33%", textAlign: "center" }}>
            <Typography variant="subtitle1">Reach</Typography>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              {creator?.predicted?.reach
                ? formatStat(creator?.predicted?.reach)
                : "N/A"}
            </Typography>
          </Box>

          <Box style={{ width: "33%", textAlign: "center" }}>
            <Typography variant="subtitle1">Engagement Rate</Typography>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              {creator?.predicted?.engagement
                ? `${formatStat(creator?.predicted?.engagement)}%`
                : "N/A"}
            </Typography>
          </Box>
        </Box> */}
      </Box>
      <Box display="flex" flexDirection="row" gap={2} flexWrap="wrap" justifyContent="center" alignItems="center">
              {
                creator?.images.map((img, index) => (
                  <img
                    src={img}
                    height={"100px"}
                    width={"100px"}
                    alt={creator?.name}
                    style={{
                
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                ))}
            </Box>
            <br/>
            <Box display="flex" justifyContent="center" alignItems="center">
            <Button disabled={creator.response.status === "matched"} gradient={creator.response.status !== "matched"} style={{fontWeight: 600, borderRadius: 10 }}
             onClick={() => {
              handleConfirmPartnership();
            }}>{creator.response.status === "matched" ? "Partnership Confirmed" : "Confirm Partnership"}</Button>
            </Box>

            <br/>

     <Box className="creator-insights-container" display="flex" flexDirection="column" gap={1} justifyContent={"flex-start"} alignItems={"flex-start"}>
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">

            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="h5"> Send a Custom Email</Typography>
            </Box>
          </Box>
          <Box style={{paddingBottom: 10, paddingTop: 1}}>
          <Typography
                            gutterBottom
                            variant="body1" sx={{ marginBottom: 2 }}
                        >
                            <br/>
                            Send this creator a custom email to clarify details of the campaign, ask key questions, and more. 
                        </Typography>
                        <br/>

            <Button
            style={{borderRadius: 10 }}
            href={`mailto:${creator.email}?subject=Gigl: New Campaign Partnership Opportunity`}
          >
            Send a Custom Email
          </Button>
        </Box>
        </Box>
      </Box>


      
    </Drawer>
  );
}

export default memo(ApplicantDrawer);
