import { Divider, IconButton } from "@mui/material";
import { EmailOutlined,LinkedIn  } from "@mui/icons-material";
import styled from "styled-components";

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    color: 'white',
    '&:hover':{
        background: "linear-gradient(to left, #4D8DFF, #C334EB )",
        boxShadow: "0px 4px 8px rgb(255, 255, 255, .1)"
    }
    
}))

const LandingFooter = () => {
    const solutionMenus = ["Discovery", "Collaboration", "Engagement", "Analytics", "Optimization", "Community"]
    const featuresMenus = ["Campaign Dashboard", "Influencer Discovery", "Campaign Management", "Performance Tracking", "Social Listening", "Affiliate Tracking"]

    return <>
        <div className="footer-container">
            <div className="footer-content">
                <div className="gigl-profile">
                    <div className="gigl-title">GIGL</div>
                    <div className="gigl-info">
                    Gigl is a comprehensive influencer marketing platform designed to streamline your campaigns. From discovering the right creators to managing collaborations, tracking performance, and leveraging AI-powered insights, Gigl provides everything you need to run successful, data-driven influencer campaigns.
                    </div>
                    <div className="gigl-social">
                    {/* <div className="social-container">
                        <img src={FacebookSVG}  alt="Facebook SVG"/> 
                        </div> */}
                        <div className="social-container">
                            <CustomIconButton size="small" onClick={() => { 
                            window.location.href = "https://www.linkedin.com/company/gigl-group";
                            }} ><LinkedIn className="white-icon" /></CustomIconButton>
                        </div>
                        {/* <div className="social-container">
                            <CustomIconButton size="small"><Instagram className="white-icon" /></CustomIconButton>
                        </div>
                        <div className="social-container">
                            <CustomIconButton size="small"> <YouTube className="white-icon" /></CustomIconButton>
                        </div> */}

                    </div>
                </div>
                <div className="gigl-menu">
                <div className="menu-container">
                <div className="footer-menu">
                    <div className="menu-title">Solution</div>
                    <div className="menu-list">
                        <ul>
                            {
                                solutionMenus.map((menu,index)=><li key={index}>{menu}</li>)
                            }
                        </ul>
                    </div>
                </div>
                <div className="footer-menu">
                    <div className="menu-title">Features</div>
                    <div className="menu-list">
                        <ul>
                            {
                                featuresMenus.map((menu,index)=><li key={index}>{menu}</li>)
                            }
                        </ul>
                    </div>
                </div>
                </div>
                <div className="contact-menu">
                <div className="footer-menu ">
                    <div className="menu-title">Contact</div>
                    <div className="menu-list">
                        <ul>
                            <li className="flex-start">
                                Have questions? <br/> Contact our support team
                            </li>
                            {/* <li className="flex-start"><LocalPhoneOutlined/> +000 123 456 789</li> */}
                            <li className="flex-start"><EmailOutlined/> support@giglgroup.com</li>
                            {/* <li className="flex-start"><LocationOnOutlined />4886 Stroman Drives, California,
                            South Stanton, USA</li> */}

                        </ul>
                    </div>
                </div>
                </div>
                </div>
            </div>
            <Divider style={{backgroundColor:"#475467",margin:"1rem 0"}} className="footer-divider"/>
            <div className="footer-end">
                <div>Copyright © {new Date().getFullYear()} by Gigl</div>
                <div>Privacy Policy | Terms & Conditions | Refund Policy</div>
            </div>
        </div>
    </>
}

export default LandingFooter;