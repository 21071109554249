import { Box, Button, Divider, FormControl, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from "@mui/material";
import { IconArrowLeft, IconArrowRight, IconCalendarEvent, IconCheck, IconChevronDown, IconPhotoUp, IconPlus, IconSparkles, IconTrash } from "@tabler/icons-react";
import { useContext, useEffect, useRef, useState } from "react";
import { COUNTRIES, INDUSTRIES } from "../util/constants";
import { useOnScreen } from "../util/hooks";
import LoadingContext from "../contexts/LoadingContext";
import { fetchGet, fetchPost, fetchPut, handleUpload, idGenerator } from "../util/helpers";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate, useParams } from "react-router-dom";

export default function CreateCampaign(props) {
    const [newCampaignData, setNewCampaignData] = useState({});
    const [addDeliverable, setAddDeliverable] = useState(false);
    const [activeDeliverable, setActiveDeliverable] = useState("");
    const [newCampaignImage, setNewCampaignImage] = useState(null);
    const { setIsLoading, setLoadingText } = useContext(LoadingContext);
    const navigate = useNavigate();
    const { campaignId } = useParams();
    const section1Ref = useRef(null);
    const isSection1Visible = useOnScreen(section1Ref);
    const section2Ref = useRef(null);
    const isSection2Visible = useOnScreen(section2Ref);
    const section3Ref = useRef(null);
    const isSection3Visible = useOnScreen(section3Ref);
    const section4Ref = useRef(null);
    const isSection4Visible = useOnScreen(section4Ref);

    const platforms = {
        "tikTok": "TikTok",
        "instagram": "Instagram",
        "youTube": "YouTube",
        "x": "X (Ex-Twitter)",
        "facebook": "Facebook",
        "linkedin": "LinkedIn",
    }
    const fetchCampaign = async () => {
        if (campaignId) {
            console.log("id", campaignId);
            const response = await fetchGet(`/brand/campaigns/${campaignId}`);
            if (response.result) {
                const result = response.result
                setNewCampaignData({ ...result, deliverables: Array.isArray(result.deliverables) ? result.deliverables : [result.deliverables] });
            }
        }
    };
    useEffect(() => {
        if (campaignId) {
            fetchCampaign();
        }
    }, [campaignId]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (newCampaignData.platforms === undefined) {
                newCampaignData.platforms = [];
            }
            if (campaignId) {
                // updating
                let imageURL = null;
                if (newCampaignImage) {
                    imageURL = await upload();
                }
                delete newCampaignData.userDocId;
                await fetchPut(
                    `/brand/campaigns/${campaignId}`,
                    {
                        ...newCampaignData,
                        image: imageURL || newCampaignData.image,
                    },
                    setIsLoading,
                );

            } else {
                // creating
                const imageURL = await upload();
                if (!imageURL) {
                    alert("Please upload an image first!");
                    return;
                }
                await fetchPost(
                    "/brand/campaigns",
                    {
                        ...newCampaignData,
                        image: imageURL,
                    },
                    setIsLoading,
                );

            }
            navigate(`/campaigns/${campaignId}`);
        } catch (error) {
            console.log("error", error);
            alert("Error submitting campaign!");
        }

        return

    };

    const handleUpdateNewCampaign = (e) => {
        setNewCampaignData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddDeliverable = () => {
        setNewCampaignData((prev) => {
            const prevDeliverables = prev.deliverables || [];
            return {
                ...prev,
                deliverables: [...prevDeliverables, activeDeliverable],
            }
        });
        setActiveDeliverable("");
        setAddDeliverable(false);
    };

    const handleRemoveDeliverable = (deliverable) => {
        setNewCampaignData((prev) => ({
            ...prev,
            deliverables: prev.deliverables.filter((d) => d !== deliverable),
        }));
    };

    const handleSelectPlatform = (platform) => {
        setNewCampaignData((prev) => {
            const prevPlatforms = prev.platforms || [];
            return {
                ...prev,
                platforms: prevPlatforms.includes(platform) ? prevPlatforms.filter((p) => p !== platform) : [...prevPlatforms, platform],
            }
        });
    };
    const upload = async () => {
        if (newCampaignImage) {
            const imagePath = idGenerator();
            const imageURL = await handleUpload(
                newCampaignImage,
                "brand-images",
                imagePath,
            );
            return imageURL;
        } else {
            alert("No image found!");
            return null;
        }
    };
    const handleToggle = (selection, key) => {
        const isSelected = selection.toLowerCase() === "yes";
        setNewCampaignData((prev) => {
            if (prev[key] === isSelected) {
                return prev; // No change needed
            }
            return {
                ...prev,
                [key]: isSelected,
            };
        });
    };

    const getGeneratedText = () => {
        if (
            !newCampaignData.description ||
            newCampaignData.description.trim() === ""
        ) {
            alert("Please fill out your brand name and description first!");
            return;
        }
        setLoadingText("Generating talking points");
        fetchPost(
            "/brand/talking_points/generate",
            {
                brandName: newCampaignData.brand,
                campaignDetails: newCampaignData.description,
            },
            setIsLoading,
        ).then((res) => {
            console.log("res", res);
            setNewCampaignData((prev) => ({
                ...prev,
                talking_points: res.message.trim(),
            }));
        });
    };

    console.log("newCampaignData", newCampaignData);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="column" spacing={2} sx={{ padding: "3%" }}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h3" color={"var(--main-text-color)"}>{newCampaignData?.name ? newCampaignData.name : "New Campaign"}</Typography>

                </Stack>
                <Stack direction="row">
                    <Stack direction="column" spacing={2} width="25%" sx={{ position: "sticky", top: "100px", height: "100%" }}>
                        <Stack direction="row" spacing={2}>
                            <Box className={(isSection1Visible || isSection2Visible || isSection3Visible || isSection4Visible) ? "gradient-circle-small" : "step-circle"} sx={{ paddingLeft: "9.5px", paddingTop: "3px" }}>
                                <Typography variant="bodyregm" color={(isSection1Visible || isSection2Visible || isSection3Visible || isSection4Visible) ? "white" : "var(--main-text-color)"}>1</Typography>
                            </Box>
                            <Typography variant="bodyregm" color={(isSection1Visible || isSection2Visible || isSection3Visible || isSection4Visible) ? "var(--main-text-color)" : "var(--body-text-color-500)"}>Basic Information</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Box className={(isSection2Visible || isSection3Visible || isSection4Visible) ? "gradient-circle-small" : "step-circle"} sx={{ paddingLeft: "9px", paddingTop: "3px" }}>
                                <Typography variant="bodyregm" color={(isSection2Visible || isSection3Visible || isSection4Visible) ? "white" : "var(--main-text-color)"}>2</Typography>
                            </Box>
                            <Typography variant="bodyregm" color={(isSection2Visible || isSection3Visible || isSection4Visible) ? "var(--main-text-color)" : "var(--body-text-color-500)"}>Content and Deliverables</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Box className={(isSection3Visible || isSection4Visible) ? "gradient-circle-small" : "step-circle"} sx={{ paddingLeft: "8.5px", paddingTop: "3px" }}>
                                <Typography variant="bodyregm" color={(isSection3Visible || isSection4Visible) ? "white" : "var(--main-text-color)"}>3</Typography>
                            </Box>
                            <Typography variant="bodyregm" color={(isSection3Visible || isSection4Visible) ? "var(--main-text-color)" : "var(--body-text-color-500)"}>Targeting and Budget</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Box className={isSection4Visible ? "gradient-circle-small" : "step-circle"} sx={{ paddingLeft: "8px", paddingTop: "3px" }}>
                                <Typography variant="bodyregm" color={isSection4Visible ? "white" : "var(--main-text-color)"}>4</Typography>
                            </Box>
                            <Typography variant="bodyregm" color={isSection4Visible ? "var(--main-text-color)" : "var(--body-text-color-500)"}>Campaign Schedule</Typography>
                        </Stack>
                    </Stack>
                    <Stack onSubmit={handleSubmit} component="form" autoComplete="off" direction="column" spacing={4} width="50%" sx={{ backgroundColor: "white", borderRadius: "var(--radius-lg)", padding: "20px" }}>
                        <Stack spacing={4} ref={section1Ref}>
                            <Stack direction="row" spacing={2}>
                                <Box className="gradient-circle-small" sx={{ paddingLeft: "9.5px", paddingTop: "3px" }}>
                                    <Typography variant="bodyregm" color={"white"}>1</Typography>
                                </Box>
                                <Typography variant="h5" color={"var(--main-text-color)"}>Basic Information</Typography>
                            </Stack>
                            <Stack direction="column" spacing={1}>
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Campaign Name</Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    name="name"
                                    value={newCampaignData?.name}
                                    onChange={handleUpdateNewCampaign}
                                    required
                                />
                            </Stack>
                            <Stack direction="column" spacing={1}>
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Brand Name</Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    name="brand"
                                    value={newCampaignData?.brand}
                                    onChange={handleUpdateNewCampaign}
                                    required
                                />
                            </Stack>
                            <Stack direction="column" spacing={1}>
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Brand Image</Typography>
                                <Stack direction="row" spacing={2} alignItems="center" paddingX={2}>
                                    {(newCampaignData?.image || newCampaignImage) ? <img src={newCampaignData?.image || URL.createObjectURL(newCampaignImage)} alt="brand" className="campaign-image-large" /> :
                                        <Box className="campaign-image-large" sx={{ backgroundColor: "var(--neutral-200)", paddingLeft: "22px", paddingTop: "22px" }}>
                                            <IconPhotoUp size="50" className="icon-colored" color="var(--body-text-color-500)" />
                                        </Box>}

                                    <Button component="label" className="outlined-button" variant="outlined" color="info">Upload Image
                                        <input type="file" name="file" accept="image/*" onChange={(e) => {
                                            const files = e.target.files;
                                            if (files !== null && files?.length > 0) {
                                                setNewCampaignImage(files[0]);
                                            }
                                        }} hidden />

                                    </Button>
                                </Stack>
                            </Stack>
                            <Stack direction="column" spacing={1}>
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Industry Focus</Typography>
                                <FormControl fullWidth required>
                                    <Select
                                        value={newCampaignData?.industry || []}
                                        name="industry"
                                        required
                                        multiple
                                        size="small"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setNewCampaignData((prev) => ({
                                                ...prev,
                                                [e.target.name]:
                                                    typeof value === "string" ? value.split(",") : value,
                                            }));
                                        }}
                                    >
                                        {INDUSTRIES.map((item) => (
                                            <MenuItem value={item} key={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack direction="column" spacing={1}>
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Campaign Description</Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    multiline
                                    name="description"
                                    value={newCampaignData?.description}
                                    onChange={handleUpdateNewCampaign}
                                    minRows={8}
                                    required
                                />
                            </Stack>
                        </Stack>
                        <Divider sx={{ width: "100%" }} />
                        <Stack spacing={4} ref={section2Ref}>
                            <Stack direction="row" spacing={2}>
                                <Box className="gradient-circle-small" sx={{ paddingLeft: "9px", paddingTop: "3px" }}>
                                    <Typography variant="bodyregm" color={"white"}>2</Typography>
                                </Box>
                                <Typography variant="h5" color={"var(--main-text-color)"}>Content and Deliverables</Typography>
                            </Stack>
                            <Stack direction="column" spacing={1} width="100%" alignItems="flex-start">
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Deliverables</Typography>
                                {newCampaignData?.deliverables && newCampaignData?.deliverables.map((deliverable) => <Stack direction="row" alignItems="flex-start" spacing={1} justifyContent="space-between" width="100%">
                                    <Stack direction="row" spacing={1} alignItems="flex-start">
                                        <IconCheck className="icon-colored" sx={{ width: "18px", height: "18px" }} color="var(--body-text-color-600)" />
                                        <Typography variant="bodysm" color={"var(--body-text-color-600)"}>{deliverable}</Typography>
                                    </Stack>
                                    <IconButton aria-label="remove deliverable" onClick={() => handleRemoveDeliverable(deliverable)}>
                                        <IconTrash className="icon-colored" color="var(--body-text-color-600)" />
                                    </IconButton>
                                </Stack>
                                )}
                                {addDeliverable && <Stack direction="row" spacing={1} width="100%">
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        name="deliverable"
                                        required
                                        value={activeDeliverable}
                                        onChange={(e) => setActiveDeliverable(e.target.value)}
                                    />
                                    <Button className="outlined-button" variant="outlined" color="info" onClick={handleAddDeliverable}>Add</Button>
                                </Stack>}
                                <Button
                                    color="info"
                                    startIcon={<IconPlus size="18" className="icon-colored" color="var(--body-text-color-600)" />}
                                    onClick={() => {
                                        setAddDeliverable(true);
                                    }}
                                >Add Deliverable</Button>
                            </Stack>
                            <Stack direction="column" spacing={1} width="100%">
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Talking Points</Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    multiline
                                    name="talking_points"
                                    defaultValue={newCampaignData?.talking_points}
                                    onChange={handleUpdateNewCampaign}
                                    minRows={4}
                                    required
                                    placeholder="Enter your talking points on seperate lines..."
                                />
                                <Button
                                    variant="contained"
                                    className="gradient-button-light"
                                    onClick={getGeneratedText}
                                    startIcon={<IconSparkles size="18" className="icon-colored" color="var(--secondary-700)" />}
                                >
                                    Get AI-Generated Talking Points
                                </Button>
                                <Typography variant="bodysmm" color={"var(--body-text-color-500)"}>*Please fill out the Basic Information before generating talking points</Typography>
                            </Stack>
                            <Stack direction="column" spacing={1} width="100%">
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Creator Level</Typography>
                                <FormControl fullWidth required>
                                    <Select
                                        value={newCampaignData?.celebrity || []}
                                        name="celebrity"
                                        required
                                        multiple
                                        size="small"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setNewCampaignData((prev) => ({
                                                ...prev,
                                                [e.target.name]:
                                                    typeof value === "string" ? value.split(",") : value,
                                            }));
                                        }}
                                    >
                                        {[
                                            { label: "Micro", value: "micro" },
                                            { label: "Major", value: "major" },
                                            { label: "Celebrity", value: "celebrity" },
                                        ].map((item) => (
                                            <MenuItem value={item.value} key={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack direction="column" spacing={1} width="100%">
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Platforms</Typography>
                                <Stack direction="row" spacing={1} flexWrap="wrap" gap={1} alignItems="center" justifyContent="center" width="90%">
                                    {Object.keys(platforms).map((platform) => <Stack direction="row" alignItems="center" spacing={1}>
                                        <Box className={`item ${newCampaignData?.platforms?.includes(platform) ? "item-selected" : "item"}`}
                                            onClick={() => {
                                                handleSelectPlatform(platform);
                                            }}>
                                            <Typography variant="bodysm" color={newCampaignData?.platforms?.includes(platform) ? "var(--main-text-color)" : "var(--body-text-color-600)"}>{platforms[platform]}</Typography>
                                        </Box>
                                    </Stack>)}
                                </Stack>
                            </Stack>
                        </Stack>

                        <Divider sx={{ width: "100%" }} />
                        <Stack spacing={4} ref={section3Ref}>
                            <Stack direction="row" spacing={2}>
                                <Box className="step-circle" sx={{ paddingLeft: "8.5px", paddingTop: "3px" }}>
                                    <Typography variant="bodyregm" color={"var(--main-text-color)"}>3</Typography>
                                </Box>
                                <Typography variant="h5" color={"var(--main-text-color)"}>Targeting and Budget</Typography>
                            </Stack>
                            <Stack direction="column" spacing={1} width="100%">
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Region</Typography>
                                <FormControl fullWidth required>
                                    <Select
                                        value={newCampaignData.region || []}
                                        name="region"
                                        required
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setNewCampaignData((prev) => ({
                                                ...prev,
                                                [e.target.name]:
                                                    typeof value === "string" ? value.split(",") : value,
                                            }));
                                        }}
                                        multiple
                                        size="small"
                                    >
                                        {COUNTRIES.map((country) => ({
                                            label: country,
                                            value: country,
                                        })).map((item) => (
                                            <MenuItem value={item.value} key={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack direction="column" spacing={1} width="100%">
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Is this a paid partnership campaign?</Typography>
                                <Stack direction="row" spacing={2}>
                                    <Box className={`item-yn ${newCampaignData.isPaid === undefined ? "item" : newCampaignData.isPaid ? "item-selected" : "item"}`}
                                        onClick={() => handleToggle("yes", "isPaid")}>Yes</Box>
                                    <Box className={`item-yn ${newCampaignData.isPaid === undefined ? "item" : !newCampaignData.isPaid ? "item-selected" : "item"}`}
                                        onClick={() => handleToggle("no", "isPaid")}>No</Box>
                                </Stack>
                            </Stack>
                            {newCampaignData.isPaid && <Stack direction="column" spacing={1} width="100%">
                                <Stack direction="row" spacing={5}>
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="bodysmm" color={"var(--main-text-color)"}>Is the budget negotiable?</Typography>
                                        <Stack direction="row" spacing={2}>
                                            <Box className={`item-yn ${newCampaignData.isNegotiable === undefined ? "item" : newCampaignData.isNegotiable ? "item-selected" : "item"}`}
                                                onClick={() => handleToggle("yes", "isNegotiable")}>Yes</Box>
                                            <Box className={`item-yn ${newCampaignData.isNegotiable === undefined ? "item" : !newCampaignData.isNegotiable ? "item-selected" : "item"}`}
                                                onClick={() => handleToggle("no", "isNegotiable")}>No</Box>
                                        </Stack>
                                    </Stack>
                                    {newCampaignData?.isNegotiable !== undefined && !newCampaignData?.isNegotiable && <Stack direction="column" spacing={1.5}>
                                        <Typography variant="bodysmm" color={"var(--main-text-color)"}>Budget Per Influencer</Typography>
                                        <FormControl fullWidth required variant="outlined">
                                            <OutlinedInput
                                                size="small"
                                                fullWidth
                                                type="number"
                                                name="budget"
                                                required
                                                value={newCampaignData?.budget}
                                                onChange={handleUpdateNewCampaign}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            />
                                        </FormControl>
                                    </Stack>}
                                </Stack>
                            </Stack>}
                            <Stack direction="column" spacing={1} width="100%">
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Is this a gift/merchandise campaign?</Typography>
                                <Stack direction="row" spacing={2}>
                                    <Box className={`item-yn ${newCampaignData.isMerchandise === undefined ? "item" : newCampaignData.isMerchandise ? "item-selected" : "item"}`}
                                        onClick={() => handleToggle("yes", "isMerchandise")}>Yes</Box>
                                    <Box className={`item-yn ${newCampaignData.isMerchandise === undefined ? "item" : !newCampaignData.isMerchandise ? "item-selected" : "item"}`}
                                        onClick={() => handleToggle("no", "isMerchandise")}>No</Box>
                                </Stack>
                            </Stack>
                            {newCampaignData.isMerchandise && (
                                <TextField
                                    name="merchandise_details"
                                    required
                                    onChange={handleUpdateNewCampaign}
                                    fullWidth
                                    multiline
                                    size="small"
                                    minRows={2}
                                    value={newCampaignData?.merchandise_details}
                                    placeholder="Share details about the merchandise and/or its monetary value..."
                                />
                            )}
                            <Stack direction="column" spacing={1} width="100%">
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Is this campaign for a creator event?</Typography>
                                <Stack direction="row" spacing={2}>
                                    <Box className={`item-yn ${newCampaignData.isEvent === undefined ? "item" : newCampaignData.isEvent ? "item-selected" : "item"}`}
                                        onClick={() => handleToggle("yes", "isEvent")}>Yes</Box>
                                    <Box className={`item-yn ${newCampaignData.isEvent === undefined ? "item" : !newCampaignData.isEvent ? "item-selected" : "item"}`}
                                        onClick={() => handleToggle("no", "isEvent")}>No</Box>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Divider sx={{ width: "100%" }} />
                        <Stack spacing={4} ref={section4Ref} >
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Box className="step-circle" sx={{ paddingLeft: "8px", paddingTop: "3px" }}>
                                    <Typography variant="bodyregm" color={"var(--main-text-color)"}>4</Typography>
                                </Box>
                                <Typography variant="h5" color={"var(--main-text-color)"}>Campaign Schedule</Typography>
                            </Stack>
                            <Stack direction="column" spacing={1} width="100%">
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Written Concept Due Date</Typography>
                                <DatePicker
                                    slots={{
                                        openPickerIcon: () => <IconCalendarEvent size="22" className="icon-colored" color="var(--body-text-color-600)" />,
                                        switchViewIcon: () => <IconChevronDown size="20" className="icon-colored" color="var(--body-text-color-600)" />,
                                        leftArrowIcon: () => <IconArrowLeft size="20" className="icon-colored" color="var(--body-text-color-600)" />,
                                        rightArrowIcon: () => <IconArrowRight size="20" className="icon-colored" color="var(--body-text-color-600)" />,
                                    }}
                                    sx={{ width: "100%" }}
                                    format={"YYYY-MM-DD"}
                                    required
                                    value={newCampaignData?.written_due ? dayjs(newCampaignData.written_due) : undefined}
                                    onChange={(value) => {
                                        handleUpdateNewCampaign({
                                            target: {
                                                name: "written_due",
                                                value: value.format("YYYY-MM-DD"),
                                            },
                                        });
                                    }} />
                            </Stack>
                            <Stack direction="column" spacing={1} width="100%">
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Draft Due Date</Typography>
                                <DatePicker
                                    slots={{
                                        openPickerIcon: () => <IconCalendarEvent size="22" className="icon-colored" color="var(--body-text-color-600)" />,
                                        switchViewIcon: () => <IconChevronDown size="20" className="icon-colored" color="var(--body-text-color-600)" />,
                                        leftArrowIcon: () => <IconArrowLeft size="20" className="icon-colored" color="var(--body-text-color-600)" />,
                                        rightArrowIcon: () => <IconArrowRight size="20" className="icon-colored" color="var(--body-text-color-600)" />,
                                    }}
                                    sx={{ width: "100%" }}
                                    format={"YYYY-MM-DD"}
                                    required
                                    value={newCampaignData?.draft_due ? dayjs(newCampaignData.draft_due) : undefined}
                                    onChange={(value) => {
                                        handleUpdateNewCampaign({
                                            target: {
                                                name: "draft_due",
                                                value: value.format("YYYY-MM-DD"),
                                            },
                                        });
                                    }} />
                            </Stack>
                            <Stack direction="column" spacing={1} width="100%">
                                <Typography variant="bodysmm" color={"var(--main-text-color)"}>Final Due Date</Typography>
                                <DatePicker
                                    slots={{
                                        openPickerIcon: () => <IconCalendarEvent size="22" className="icon-colored" color="var(--body-text-color-600)" />,
                                        switchViewIcon: () => <IconChevronDown size="20" className="icon-colored" color="var(--body-text-color-600)" />,
                                        leftArrowIcon: () => <IconArrowLeft size="20" className="icon-colored" color="var(--body-text-color-600)" />,
                                        rightArrowIcon: () => <IconArrowRight size="20" className="icon-colored" color="var(--body-text-color-600)" />,
                                    }}
                                    sx={{ width: "100%" }}
                                    format={"YYYY-MM-DD"}
                                    required
                                    value={newCampaignData?.final_due ? dayjs(newCampaignData.final_due) : undefined}
                                    onChange={(value) => {
                                        handleUpdateNewCampaign({
                                            target: {
                                                name: "draft_due",
                                                value: value.format("YYYY-MM-DD"),
                                            },
                                        });
                                    }} />
                            </Stack>
                        </Stack>
                        <Divider sx={{ width: "100%" }} />
                        <Stack direction="row" spacing={1} width="100%" justifyContent="flex-end">
                            <Stack direction="row" spacing={2}>
                                <Button className="outlined-button" variant="outlined" color="info" onClick={() => {
                                    if (campaignId) {
                                        navigate(`/campaigns/${campaignId}`);
                                    } else {
                                        navigate(-1)
                                    }
                                }}>Cancel</Button>
                                <Button type="submit" className="gradient-button" variant="contained">{newCampaignData?.name ? "Save" : "Create Campaign"}</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </LocalizationProvider>
    )
}
