import { Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import blogpic3 from "../assets/blogpic3.PNG";
import blogpic2 from "../assets/blogpic2.PNG";

import * as React from "react";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

export default function BlogArticle3(props) {
  const isMobile = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          float: isMobile ? "" : "left",
          maxWidth: isMobile ? "100vw" : "50vw",
          marginLeft: isMobile ? 0 : 100,
          textAlign: isMobile ? "left" : "left",
          height: 200,
        }}
      >
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 16 : 45,
            width: isMobile ? "80%" : "70%",
            fontWeight: 600,
            marginTop: 20,
          }}
        >
          How to measure influencer marketing
        </p>
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 25,
            width: isMobile ? "80%" : "70%",
          }}
        >
          There are many ways to measure the success of your influencer
          marketing campaign, and they largely depend on your goals - Is it
          brand awareness? Sales? Engagement?
          <br></br>
          <br></br>
          By: Gigl Expert
          <br></br>
          Date: 01/05/2023
        </p>
      </div>

      {isMobile ? (
        <p></p>
      ) : (
        <>
          <Grid
            elevation={3}
            sx={{
              height: isMobile ? 340 : 420,
              float: isMobile ? "" : "right",
              borderRadius: 10,
              marginBottom: 2,
              width: "35vw",
              marginRight: 10,
            }}
          >
            <img
              src={blogpic3}
              height="100%"
              style={{
                borderRadius: 20,
                maxHeight: isMobile ? 400 : 500,
                maxWidth: isMobile ? "40vw" : "",
                ...(isMobile && { marginTop: 10 }),
              }}
            ></img>
          </Grid>
        </>
      )}

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#4158D0",
          backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          minHeight: 30,
          marginBottom: 100,
        }}
      ></Grid>

      <div
        style={{
          float: isMobile ? "left" : "center",
          maxWidth: isMobile ? "100vw" : "80vw",
          marginLeft: isMobile ? 0 : 100,
          textAlign: isMobile ? "left" : "left",
          height: isMobile ? 1900 : 1900,
        }}
      >
        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 5 : 5,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          1. How do you measure the Engagement of your influencer marketing
          campaign?
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 50,
            height: isMobile ? 160 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >
          Engagement is an immediate way to track the success of your campaign.
          Increased positive engagement is a great way to measure whether your
          product/brand is liked, and your content is winning.
          <br></br>
          <br></br>
          Total Engagement = Like count + comment count + share count + save
          count
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          2. How do you measure Brand Awareness of your influencer marketing
          campaign?
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            marginBottom: 50,
            height: isMobile ? 250 : "",

            width: isMobile ? "80%" : "80%",
          }}
        >
          Views and reach are key indicators of great content. High views show
          that your content is being watched and shared with larger audiences.
          If your goal is increased brand awareness, then you should be pushing
          for a larger amount of views on your posts.
          <br></br>
          If this is your goal, you should partner with creators who have a
          clear performance history with high view counts. These are typically
          major to celebrity creators. You can partner with many established and
          large influencers on platforms like Gigl.
          <br></br>
          <br></br>
          Total Brand Awareness = Views across channels and stories.
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          3. How do you measure Conversions of your influencer marketing
          campaign?
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            height: isMobile ? 250 : "",
          }}
        >
          If your goal is the conversion of viewers into intent consumers, then
          you can measure the clicks to site from your influencer marketing. You
          can add website links to the post caption, as a story link sticker, or
          into the creator bio. Tracking clicks to site is not an insight
          available in social media analytics.
          <br></br>
          If you want to track clicks to site and conversions, you should join
          Gigl. On gigl you can generate your custom site/product link and track
          all clicks of that link to your site. This allows you to measure how
          many people were driven to your site via your influencer marketing
          campaign.
          <br></br>
          <br></br>
          Conversion = total #clicks to your website/product link from an
          influencer post
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          4. How do you measure sales from your influencer marketing campaign?
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            height: isMobile ? 280 : "",
          }}
        >
          Increased Sales is the goal of many influencer marketing campaigns,
          especially for product-based businesses. But measuring exact number of
          sales from a specific creator can be more difficult.
          <br></br>
          Why? There are several factors - for example, people could see an
          influencer's post and then search up the product online. As a result,
          it's challenging to track the exact amount of sales numbers from a
          creator.
          <br></br>
          <br></br>
          Here are some ways to track sales from a specific creator:
          <br></br>
          ➡️ Unique product/website links provided to a creator (you can
          generate one of Gigl)
          <br></br>
          ➡️ Unique promo codes for a creator - Ex: anyone that uses promo code
          Creator1, gets 5% off.
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 15 : 20,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
          }}
        >
          5. How do you measure Return on Investment (ROI) from your influencer
          marketing campaign?
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginBottom: 50,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            height: isMobile ? 100 : "",
          }}
        >
          This answer largely depends on how you are calculating ROI. You may be
          tracking for brand awareness, engagement, conversions, or sales.
          <br></br>
          <br></br>
          ROI = [ The metric you are tracking ] / Cost of your campaign X 100
        </p>

        <p
          style={{
            width: isMobile ? "80vw" : "35vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 16,
            marginTop: isMobile ? 10 : 10,
            width: isMobile ? "80%" : "80%",
            fontWeight: 600,
            marginTop: 50,
            height: isMobile ? 80 : "",
          }}
        >
          Want to take your business to the next level through social media
          marketing?
          <br></br>
          Sign up on Gigl to launch your next influencer marketing campaign.
        </p>

        <Button
          style={{
            display: "block",
            backgroundColor: "black",
            fontSize: isMobile ? 9 : 15,
            marginTop: 40,
            marginLeft: isMobile ? 30 : 0,
          }}
          onClick={() => navigate("/register")}
        >
          Get Started on Gigl Today
        </Button>
      </div>

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#4158D0",
          backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          minHeight: 30,
        }}
      ></Grid>

      <div
        style={{
          float: isMobile ? "" : "right",
          maxWidth: isMobile ? "100vw" : "40vw",
          textAlign: isMobile ? "left" : "left",
          height: isMobile ? 250 : 320,
          marginRight: isMobile ? 0 : 100,
          marginTop: 50,
          height: isMobile ? 350 : 450,
        }}
      >
        <Typography
          style={{
            fontSize: isMobile ? 15 : 18,
            marginBottom: 10,
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
          }}
        >
          Check Out the Next Article
        </Typography>

        <Typography
          style={{
            fontSize: isMobile ? 20 : 30,
            fontWeight: 600,
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
          }}
        >
          3 Tips to launch a successful social media campaign, according to top
          creators
        </Typography>

        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 20,
          }}
        >
          We asked @feeedmi, one of the top food and lifestyle influencers in
          Canada her tips for brands to be more successful on social media. Her
          channel has over 1.4 Million followers and 1 Billion views...
        </p>
        <p
          style={{
            width: isMobile ? "80vw" : "40vw",
            ...(isMobile && { margin: "0 auto" }),
            fontSize: isMobile ? 10 : 15,
            marginTop: 10,
            fontStyle: "italic",
          }}
        >
          {" "}
          - @feeedmi | 1.4 Million Followers | Top Gigl Creator
        </p>

        <Button
          style={{
            display: "block",
            backgroundColor: "#4158D0",
            backgroundImage:
              "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            fontSize: isMobile ? 9 : 15,
            marginTop: isMobile ? 20 : 40,
            marginRight: isMobile ? 0 : 300,
            marginLeft: isMobile ? 40 : 0,
          }}
          onClick={() => navigate("/blog/blogarticle2")}
        >
          Read the Full Article
        </Button>
      </div>

      {isMobile ? (
        <p></p>
      ) : (
        <>
          <img
            src={blogpic2}
            height="100%"
            style={{
              borderRadius: 20,
              maxHeight: isMobile ? 500 : 380,
              maxWidth: isMobile ? "60vw" : "65vw",
              ...(isMobile && { marginTop: 10 }),
              marginLeft: isMobile ? 80 : 100,
              marginTop: isMobile ? 35 : 20,
            }}
          ></img>
        </>
      )}

      <Grid
        container
        sx={{
          margin: "0 auto",
          textAlign: "center",
        }}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: "#4158D0",
          backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
          minHeight: 20,
        }}
      ></Grid>

      <Grid
        container
        spacing={1}
        sx={{
          width: isMobile ? "100vw" : "80vw",
          margin: "0 auto",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        <Grid item xs={isMobile ? 12 : 4}>
          <div
            style={{ display: isMobile ? "" : "flex", alignItems: "center" }}
          >
            <Typography variant="h3"> GIGL</Typography>
          </div>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/privacy")}
          >
            Privacy Policy
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/terms")}
          >
            Terms and Conditions
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 5, cursor: "pointer", fontSize: 9 }}
            onClick={() => navigate("/limited-use")}
          >
            Limited Use
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          {isMobile ? (
            <p style={{ fontSize: 9 }}>Toronto, Ontario, Canada</p>
          ) : (
            <>
              {" "}
              <p style={{ fontSize: 9 }}>Toronto</p>
              <p style={{ fontSize: 9 }}>Ontario, Canada</p>
              <p style={{ fontSize: 9 }}>giglgroup.com</p>
            </>
          )}
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <p style={{ fontSize: 9, marginRight: 15, marginLeft: 15 }}>
            Contact Us: support@giglgroup.com
          </p>
          <p style={{ fontSize: 8, marginRight: 15, marginLeft: 15 }}>
            Copyright @ Gigl 2023.
          </p>
        </Grid>
      </Grid>
    </>
  );
}
