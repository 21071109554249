import { useEffect, useState } from "react";
import { fetchGet, fetchPost } from "../util/helpers";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Accordion, AccordionDetails, AccordionSummary, Button, Link, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Scraper() {
    const [keyword, setKeyword] = useState("");
    const [location, setLocation] = useState("");
    const [scraperData, setScraperData] = useState([]);
    const columns = [
        { field: 'Email', headerName: 'Email', width: 250 },
        { field: 'title', headerName: 'Title', width: 150 },
        { field: 'Description', headerName: 'Description',  minWidth: 500 },
        { field: 'Detail_Link', headerName: 'Link', minWidth: 200, renderCell: (params) => 
            <Link href={params.row.Detail_Link} target="_blank">{params.row.Detail_Link.replace("https://www.instagram.com/", "")}</Link> },
      ];
    useEffect(() => {
        const fetchData = () => {
            fetchGet("/admin/instagram/scrape")
            .then(data => setScraperData(data.result.map(set => ({...set, items: set.items.map(item => ({...item, id: item.Detail_Link}))}))));
        };

        fetchData();
        const intervalId = setInterval(fetchData, 10000);

        return () => clearInterval(intervalId);
    }, [])
    return <div>
        <Typography variant="h6">Scrape New Data</Typography>
            <TextField fullWidth label="Keyword To Search" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
            <TextField  fullWidth label="Location To Search (optional - leave blank for all locations)" value={location} onChange={(e) => setLocation(e.target.value)} />
            <Button style={{ marginTop: 10, marginBottom: 10 }} variant="contained" color="primary" onClick={() => {
                if (!keyword) {
                    alert("Please enter a keyword to search for.");
                    return
                }
                let body = { keyword }
                if (location) {
                    body.location = location;
                }
                fetchPost("/admin/instagram/scrape", body)
                alert("Scrape started! Please refresh the page in a minute to see the results.");
            }}>Scrape</Button>
        {scraperData.map(dataSet => {
    
        return (
            <>
            
            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography component="span">ID: {dataSet.id} | Started at {new Date(dataSet.date).toLocaleString()}</Typography>
            </AccordionSummary>
            <AccordionDetails>
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid rows={dataSet.items} columns={columns} slots={{ toolbar: GridToolbar }} />
          </div>
          </AccordionDetails>
          </Accordion></>)
    })}</div>
}