import { useEffect, useState, useContext, memo, useMemo } from "react";
import { fetchGet, fetchPost, fetchPut, getRandomNumber, getStringHash } from "../util/helpers";
import toast, { Toaster } from "react-hot-toast";
import {Button} from "../components";
import { useNavigate } from "react-router-dom";
import UserContext from "../contexts/UserContext";

import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Box
} from "@mui/material";
import outreachEmail from "../assets/Gigl Email Outreach.PNG";
import { IconMail, IconSearch } from "@tabler/icons-react";
import { useTheme } from "@mui/material";
import OutreachDrawer from "./OutreachDrawer";

const followerComparator = (a, b) => {
  return followerCountToNumber(a) - followerCountToNumber(b);
};
const followerCountToNumber = (followers) => {
  let amountMultiplier = followers.slice(-1).toLowerCase();
  if (amountMultiplier === "k") {
    amountMultiplier = 1000;
  } else if (amountMultiplier === "m") {
    amountMultiplier = 1000000;
  } else {
    amountMultiplier = 1;
  }
  const amount = parseFloat(followers.slice(0, -1));
  return amount * amountMultiplier;
};
function NewOutreachTable(props) {
  const [outreach, setOutreach] = useState([]);
  const { campaignId } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmCreatorCardOpen, setConfirmCreatorCardOpen] = useState(false);
  const [creatorName, setCreatorName] = useState("");
  const [creatorId, setCreatorId] = useState("");
  const [creatorEmail, setCreatorEmail] = useState("");
  const [creatorLink, setCreatorLink] = useState("");
  const [creatorData, setCreatorData] = useState(null);
  const [creatorHandle, setCreatorHandle] = useState("");
  const [creatorIndustry, setCreatorIndustry] = useState("");
  const [creatorLocation, setCreatorLocation] = useState("");
  const [creatorCity, setCreatorCity] = useState("");
  const [creatorRelevance, setCreatorRelevance] = useState("");
  const [creatorFollowers, setCreatorFollowers] = useState("");
  const [creatorReach, setCreatorReach] = useState("");
  const [creatorLevelRelevance, setCreatorLevelRelevance] = useState("any");
  const [creatorLevelOutreach, setCreatorLevelOutreach] = useState("any");
  const [creatorGenderSearch, setCreatorGenderSearch] = useState("any");
  const [creatorEthnicitySearch, setCreatorEthnicitySearch] = useState("any");
  const [creatorAudienceTier, setCreatorAudienceTier] = useState("any");
  const [locationSearch, setLocationSearch] = useState("");
  const navigate = useNavigate();
  const [contactStatus, setContactStatus] = useState("any");
  const [nameSearch, setNameSearch] = useState("");
  const [favoriteOutreach,setFavoriteOutreach] = useState([]);
  const [isFavorite, setIsFavorite] = useState({});
  const { user } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();

  const STATUS_TO_LABEL = {
    "not-contacted": "Not contacted",
    delivered: "Delivered",
    opened: "Opened",
    clicked: "Clicked",
    failed: "Failed",
  };
  
  const STATUS_TO_COLOR = {
    "not-contacted": {backgroundColor: theme.palette.info.light, color: theme.palette.info.main},
    delivered: {backgroundColor: theme.palette.success.light, color: theme.palette.success.light},
    opened: {backgroundColor: "#6bc1ff", color: "#135687"}, //Blue, no blue option in our palette so this is custom 
    clicked: {backgroundColor: theme.palette.secondary.light, color: theme.palette.secondary.main},
    failed: {backgroundColor: theme.palette.error.light, color: theme.palette.error.main},
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headers = [ "Creator", "Followers", "Industry", "Location", "Relevance", "Outreach Status", "Actions" ]

  const fetchOutreach = () => {
    fetchGet(`/brand/outreach?campaignId=${campaignId}`)
      .then((result) => result.result)
      .then((result) => {
        setOutreach(result.map((res) => ({ ...res, id: res.email })));
      });
  };
  const fetchFavoriteOutreach = async () => {
    const data = await fetchGet(`/brand/outreach/favorite/${user.userDocId}`);
    setFavoriteOutreach(data?.data || []);
  }
  const sendEmail = () => {
    fetchPost(`/brand/outreach`, {
      campaignId: campaignId,
      email: creatorEmail,
    })
      .then(() => {
        toast.success(
          `Successfully sent an email to ${creatorName}. Check the status of this email in your outreach table.`,
        );
        setConfirmOpen(false);
        fetchOutreach();
      })
      .catch(() => {
        toast.error(
          `Failed to send an email to ${creatorName}. Please check back later.`,
        );
      });
  };

  useEffect(() => {
    fetchOutreach();
    fetchFavoriteOutreach();
  }, [campaignId]);


  const closeDialog = () => {
    setConfirmOpen(false);
    setCreatorEmail("");
    setCreatorName("");
  };

  const closeCreatorCardDialog = () => {
    setConfirmCreatorCardOpen(false);
    setCreatorEmail("");
    setCreatorName("");
    setCreatorLink("");
    setCreatorData({});
    setCreatorHandle("");
    setCreatorLocation("");
    setCreatorCity("");
    setCreatorRelevance("");
    setCreatorFollowers("");
    setCreatorReach("");
    setIsFavorite({});
  };

  const checkIsFavoriteCreator = (selectedCreator) => {
    const favoriteData = favoriteOutreach.filter((data)=>data.outreach_emailid === selectedCreator.email);
    setIsFavorite(favoriteData[0]||{});
  }
  
  const handleFavorite = async (status) => {
    if(!isFavorite.id){
      const result = await fetchPost("/brand/outreach/favorite",{
        "outreach_emailid":creatorData.email,
        "favorite_status":status
      });
      const resultData = {...result.data};
      const newList = [...favoriteOutreach];
      newList.push({...resultData});

      setFavoriteOutreach([...newList]);
      setIsFavorite({...resultData});
    }else{
      const result = await fetchPut(`/brand/outreach/favorite/${isFavorite.id}`,{...isFavorite,"favorite_status":status});
      const resultData = {...result.data};
      const newList = favoriteOutreach.map((data)=>data.id === isFavorite.id? {...resultData}:{...data});
      setFavoriteOutreach([...newList]);
      setIsFavorite({...resultData});
    }
  }
  const rows = useMemo(() => {
    const checkName = (row) => {
      if (nameSearch) {
        if (
          row.name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          row.handle.toLowerCase().includes(nameSearch.toLowerCase())
        ) {
          return true;
        }
      } else {
        return true;
      }
    }
    return outreach.filter(checkName);
  }, [outreach, nameSearch]);
  console.log(outreach);

  return (
    <div style={{width: "100%" }}>
      <OutreachDrawer campaignId={campaignId} open={confirmCreatorCardOpen} onClose={() => setConfirmCreatorCardOpen(false)} creator={creatorData} setCreator={setCreatorData}/>
       <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>
          Outreach To This Creator Using Gigl's Customized Email Template
        </DialogTitle>
        <DialogContent>
          By outreaching to a creator, you are inviting them to apply for your
          campaign. If this creator is interested in working with you, they will
          show up in your applications section above. Are you sure you want to
          outreach to {creatorName}?
          <br />
          <br />
          <img
            alt="outreach-email"
            src={outreachEmail}
            height="100%"
            style={{
              maxHeight: 700,
              maxWidth: 750,
            }}
          ></img>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>No</Button>

          <Button
            onClick={sendEmail}
            style={{
              backgroundColor: "#4158D0",
              backgroundImage:
                "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="end">
                <TextField
                  placeholder="Search Creators"
                  size="small"
                  variant="outlined"
                  color="info"
                  onChange={(e) => setNameSearch(e.target.value)}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconSearch
                            className="icon-colored"
                            size="18"
                            color={theme.palette.info.main}
                          />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                {/* <Button
                  variant="outlined"
                  className="outlined-button"
                  color="info"
                  startIcon={
                    <IconUserPlus
                      className="icon-colored"
                      size="18"
                      color={theme.palette.info.main}
                    />
                  }
                >
                  Invite from my List
                </Button> */}
              </Stack>
      <Stack direction="column" spacing={1} alignItems="center">
      <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    {headers.map((header) => (
                    <TableCell>
                        <Typography variant="bodysm" color="var(--body-text-color-600)">{header}</Typography>
                    </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows && rows.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((creator) =>
                    <TableRow>
                        <TableCell align="left" width="20%">
                            <Stack direction="row" spacing={2} alignItems="center" onClick={() => {setConfirmCreatorCardOpen(true); setCreatorData(creator)}}>
                                <img className="profile-image" src={creator?.profileImage ? creator?.profileImage : `../gradient${getStringHash(creator?.name, 10)}.png`} alt={creator.name}/>
                                <Stack direction="column" spacing={0}>
                                    <Typography variant="bodyregm" color="var(--main-text-color)">{creator.name}</Typography>
                                </Stack>
                            </Stack>
                        </TableCell>
                        <TableCell align="left" width="12%">
                            <Typography variant="bodysm" color="var(--main-text-color)">{creator.followers}</Typography>
                        </TableCell>
                        <TableCell align="left" width="20%">
                            <Typography variant="bodysm" color="var(--main-text-color)">{creator.industry}</Typography>
                        </TableCell>
                        <TableCell align="left" width="12%">
                            <Typography variant="bodysm" color="var(--main-text-color)">{creator.location?.join(", ")}</Typography>
                        </TableCell>
                        <TableCell align="left" width="12%">
                            <Typography variant="bodysm" color="var(--main-text-color)">{creator.relevance?.join(", ")}</Typography>
                        </TableCell>
                        <TableCell align="left" width="12%">
                            <Chip size="small" style={{color: 'black'}} label={STATUS_TO_LABEL[creator.status]} sx={STATUS_TO_COLOR[creator.status]}/>
                        </TableCell>
                        <TableCell align="left" width="12%"> 
                          <Box display="flex" gap={2} flexDirection="row">
                        <Button
                            onClick={() => {
                              setConfirmCreatorCardOpen(true);
                              setCreatorData(creator);
                            }}
                            variant="outlined" className="outlined-button" color="info">Learn More</Button>
                            <Button
                            onClick={() => {
                              setConfirmOpen(true);
                              setCreatorName(creator.name);
                              setCreatorEmail(creator.email);
                            }}
                            variant="outlined" className="outlined-button" color="info" startIcon={<IconMail className="icon-colored" size="18" color={theme.palette.info.main}/>}>Outreach</Button>
                            </Box>
                        </TableCell>
                    </TableRow> 
                )}
            </TableBody>
        </Table>
    </TableContainer>
    <TablePagination
      count={outreach.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
    </Stack>
      <Toaster position="top-center" toastOptions={{ duration: 3000 }} />
     

      <Toaster
        position="top-center"
        toastOptions={{ duration: 3000 }}
        style={{ width: 5000 }}
      />

    </div>
  );
}

export default memo(NewOutreachTable);