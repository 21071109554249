import { useEffect, useState, useContext, memo, useMemo } from "react";
import { fetchGet, fetchPost, fetchPut, formatStat, getRandomNumber, getStringHash } from "../util/helpers";
import toast, { Toaster } from "react-hot-toast";
import {Button, LearnMoreDialog} from "../components";
import { useNavigate } from "react-router-dom";
import UserContext from "../contexts/UserContext";

import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@mui/material";
import outreachEmail from "../assets/Gigl Email Outreach.PNG";
import { IconBrandFacebook, IconBrandInstagram, IconBrandTiktok, IconBrandYoutube, IconMail } from "@tabler/icons-react";
import { useTheme } from "@mui/material";
import LoadingContext from "../contexts/LoadingContext";
import ApplicantDrawer from "./ApplicantDrawer";


const followerComparator = (a, b) => {
  return followerCountToNumber(a) - followerCountToNumber(b);
};
const followerCountToNumber = (followers) => {
  let amountMultiplier = followers.slice(-1).toLowerCase();
  if (amountMultiplier === "k") {
    amountMultiplier = 1000;
  } else if (amountMultiplier === "m") {
    amountMultiplier = 1000000;
  } else {
    amountMultiplier = 1;
  }
  const amount = parseFloat(followers.slice(0, -1));
  return amount * amountMultiplier;
};

const platformToIcon = {
  "youtube": <IconBrandYoutube size="25" className="icon-colored" color="var(--main-text-color)"/>,
  "tiktok": <IconBrandTiktok size="25" className="icon-colored" color="var(--main-text-color)"/>,
  "facebook": <IconBrandFacebook size="25" className="icon-colored" color="var(--main-text-color)"/>,
  "instagram": <IconBrandInstagram size="25" className="icon-colored" color="var(--main-text-color)"/>
  }
function NewApplicantTable(props) {
  const {  campaignId } = props;
  const [creators, setCreators] = useState([]);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { setIsLoading } = useContext(LoadingContext);
  const [open, setOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const theme = useTheme();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headers = [ "Creator", "Followers", "Reach", "Industry", "Platform","Actions" ]


  const fetchCreators = async () => {
    setIsLoading(true);
    console.log("fetching creators");
    if (campaignId) {
      console.log("id", campaignId);
      const response = await fetchGet(
        `/brand/campaigns/${campaignId}/responses`,
      );
      if (response.result) {
        const creatorTemp = [...response.result];
        creatorTemp.sort(
          (a, b) =>
            (b.stats ? b.stats.totalFollowers : 0) -
            (a.stats ? a.stats.totalFollowers : 0),
        );
        if (
          creatorTemp.length > 0 &&
          creatorTemp[0].stats &&
          creatorTemp[0].stats.totalFollowers > 100000
        ) {
        }
        setCreators(response.result);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCreators();
  }, [campaignId]);

  const rows = useMemo(() => {
    return creators.filter(creator => creator.name.toLowerCase().includes(props?.nameSearch?.toLowerCase()));
  }, [creators, props?.nameSearch]);
  console.log("creators", creators);

  return (
    <div style={{width: "100%" }}>
      <ApplicantDrawer campaignId={campaignId} open={open} onClose={() => setOpen(false)} creator={selectedCreator} setCreator={setSelectedCreator}/>
      <Stack direction="column" spacing={1} alignItems="center">
      <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    {headers.map((header) => (
                    <TableCell>
                        <Typography variant="bodysm" color="var(--body-text-color-600)">{header}</Typography>
                    </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows && rows.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((creator) =>
                    <TableRow>
                        <TableCell align="left" width="20%">
                            <Stack direction="row" spacing={2} alignItems="center" >
                                <img className="profile-image" src={creator?.images?.length > 0 ? creator?.images[0] : `../gradient${getStringHash(creator?.name, 10)}.png`} alt={creator.name}/>
                                <Stack direction="column" spacing={0}>
                                    <Typography variant="bodyregm" color="var(--main-text-color)">{creator.name}</Typography>
                                </Stack>
                            </Stack>
                        </TableCell>
                        <TableCell align="left" width="12%">
                            <Typography variant="bodysm" color="var(--main-text-color)">{formatStat(creator?.stats?.totalFollowers)}</Typography>
                        </TableCell>
                        <TableCell align="left" width="12%">
                            <Typography variant="bodysm" color="var(--main-text-color)">{formatStat(creator?.stats?.totalReach)}</Typography>
                        </TableCell>
                        <TableCell align="left" width="25%">
                            <Typography variant="bodysm" color="var(--main-text-color)">{creator?.industries?.join(", ")}</Typography>
                        </TableCell>
                        <TableCell align="left" width="20%">
                        <Stack direction="row" spacing={1} alignItems="center" sx={{width: "100%"}}>
                            {creator?.stats?.channels?.map(channel => platformToIcon[channel.platform])}
                            </Stack>
                        </TableCell>

                        <TableCell align="left" width="12%">
                          <Stack direction="row" spacing={1} alignItems="center" sx={{width: "100%"}}>
                            <Button variant="outlined" className="outlined-button" color="info" onClick={() => {setOpen(true); setSelectedCreator(creator)}}>View More</Button>
                            </Stack>
                        </TableCell>
                    </TableRow> 
                )}
            </TableBody>
        </Table>
    </TableContainer>
    <TablePagination
      count={creators.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
    </Stack>
      <Toaster position="top-center" toastOptions={{ duration: 3000 }} />
     

      <Toaster
        position="top-center"
        toastOptions={{ duration: 3000 }}
        style={{ width: 5000 }}
      />

    </div>
  );
}

export default memo(NewApplicantTable); 